import { once } from 'lodash';
import { createStore, applyMiddleware } from 'redux';
// import { composeWithDevTools } from 'remote-redux-devtools';
import { enableBatching } from 'redux-batched-actions';
import thunk from '@wix/redux-thunk';
import {
  CREATE_COMMENT,
  CREATE_POST,
  DELETE_COMMENT,
  DELETE_POST,
  LIKE_COMMENT,
  LIKE_POST,
  UPDATE_COMMENT,
  UPDATE_POST,
} from '../constants/interactions';
import { DELETE_POST_SUCCESS } from '../actions/delete-post';
import { DELETE_COMMENT_SUCCESS } from '../actions/delete-comment';
import createRequest from '@wix/communities-forum-client-commons/dist/src/services/create-request';
import { fetchAllCategories } from '../../common/actions/fetch-categories';
import { fetchForumData } from '../actions/fetch-forum-data';
import { fetchLocalStorage } from '../actions/fetch-local-storage';
import { subscribeToChange } from '../../common/services/state-optimizer';
import {
  isMaInstalled,
  setCommunitiesContext,
  initIsMembersChatEnabled,
} from '../../common/store/communities-context/communities-context-actions';
import { setBasicParams, setInstance } from '../../common/store/basic-params/basic-params-actions';
import { setStyleParams } from '../../common/store/style-params/style-params-actions';
import { fetchExperiments } from '../../common/actions/fetch-experiments';
import { createSentryMonitor, ENVIRONMENT } from '../../common/services/monitoring';
import { isRtlLanguage } from '../../common/services/is-rtl-language';
import { fetchUser } from '../../common/actions/fetch-user';
import {
  fetchTranslations,
  setCommentsTranslations,
} from '../../common/store/translations/translations-actions';
import {
  createDuplexerClient,
  createDuplexerMiddleware,
  socketEventHandlers,
} from '../middleware/duplexer-middleware';
import noopMiddleware from '../middleware/noop-middleware';
import createLocalStorageMiddleware from '../middleware/local-storage-middleware';
import rootReducer from '../reducers';
import {
  createModalClosedMiddleware,
  createModalOpenedMiddleware,
} from '../../common/modals/framework/initialize-store';
import { modalResolverMapByType } from '../components/modals/modal-resolver-map-by-type';
import { modalDataLoaderByType } from '../components/modals/modal-data-loader-map-by-type';
import { setRouterMatch } from '../../common/router';
import { transformWixCodeLocation } from '../../common/store/location/transform-wix-code-location';
import { createRouter } from './create-router';
import { INCREMENT_COMMENT_LIKE_COUNT_SUCCESS } from '../actions/increment-comment-like-count';
import { INCREMENT_POST_LIKE_COUNT_SUCCESS } from '../../common/actions/increment-post-like-count';
import { createMessageMiddleware } from '../../common/messages/framework/initialize-store';
import messageResolvers from '../components/messages/message-resolvers';
import createShowMessage from '../../common/messages/framework/create-show-message';
import { emitForumOpened } from '../actions/forum-socket';
import { CREATE_POST_SUCCESS } from '../actions/create-post';
import { UPDATE_POST_SUCCESS } from '../actions/update-post';
import { CREATE_COMMENT_SUCCESS } from '../actions/create-comment';
import { UPDATE_COMMENT_SUCCESS } from '../actions/update-comment';
import { initializeLocation, setLocation } from '../../common/store/location/location-actions';
import { initializeMaNavigation } from '../../common/store/ma-navigation/ma-navigation-actions';
import { parseInstance } from '@wix/communities-forum-client-commons/dist/src/services/parse-instance';
import { addErrorState, addDebugState } from '../../common/store/debug-state/debug-state-actions';
import { handleError } from '../../common/store/debug-state/handle-error';
import createInteractionsMiddleware from '../../common/interactions/create-interactions-middleware';
import { initialiseInstanceValues } from '../../common/store/instance-values/instance-values-actions';
import { initBiMiddleware } from '../bi-events/init-middleware';
import { FORUM_SECTION_ID } from '@wix/communities-forum-universal/dist/src/constants/appsConfig';
import listenToSettingsChange, { applySettingsChange } from '../services/listen-to-settings-change';
import createRouterMiddleware from '../middleware/create-router-middleware';
import { handleProvisioning } from '../../common/services/handle-provisioning';
import { createFastFormSubmitMiddleware } from '../../common/components/fast-form/store/fast-form-submit-middleware';
import { fetchBadges } from '../actions/fetch-badges';
import { invokeSavedAction } from '../services/invoke-saved-action';
import { getLocation } from '../../common/store/location/location-selectors';
import BiLocationTracker from '../bi-events/bi-location-tracker';
import createRefreshAfterOnlineMigrationMiddleware from '../middleware/create-refresh-after-offline-migration-middleware';
import globalLoaderMiddleware from '../middleware/global-loader-middleware';
import { uouBiEventsExecutor } from '../bi-events/forums-uou-events-executor';
import { initDevToolsStore } from '../components/dev-tools/dev-tools-store';
import { fetchMembersGroupsAndPaidPlans } from '../actions/fetch-members-groups-and-paid-plans';
import { showActivateSubscriptionMessageIfNeeded } from '../actions/show-activate-subscriptions-message';
import { isSSR } from '../../common/store/basic-params/basic-params-selectors';
import {
  onBeforePostCreateHook,
  onBeforePostUpdateHook,
  onFetchExternalLinksHook,
  onBeforeCommentCreateHook,
  onBeforeCommentUpdateHook,
} from './forum-external-hooks';
import { FORUM_APP_ID } from '@wix/communities-forum-universal/dist/src/constants/appsConfig.prod';
import { getCurrentInstance } from '../../common/services/get-instance';
import { createThrottleMiddleware } from '../../common/store/throttle-middleware/throttle-middleware';
import forumDisplayStateMiddleware from '../middleware/forum-display-state-middleware';
import { isExperimentEnabled } from '../selectors/experiments-selectors';
import {
  EXPERIMENT_WIX_COMMENTS,
  EXPERIMENT_DISABLE_API_RETRIES,
} from '@wix/communities-forum-client-commons/dist/src/constants/experiments';
import { setCustomRoutes } from '../../common/store/custom-routes/custom-routes-actions';
import { getStaticsVersionFromUrl } from '../../common/services/statics-version';
import { initializeCommentsController } from '@wix/comments-ooi-client/controller';
import { getDuplexerClient } from '../middleware/duplexer-middleware/create-duplexer-client';
import { setInstalledApps } from '../actions/vertical-embed-actions';
import { makeRelativeIfNeeded } from './make-relative-path-if-needed';
import { configureCommentsApi } from './configure-comments-api';
import { handleInitialRequestFailure } from './handle-initial-request-failure';
import { getCurrentSiteLanguage } from './get-current-site-language';
import {
  createControllerActions,
  createControllerPromisifiedActions,
} from './create-controller-actions';

const isProduction = process.env.NODE_ENV === 'production';

export const createForumAppController = (
  { appParams, compId, config, setProps, wixCodeApi, platformAPIs, type },
  flowAPI,
) => {
  const isEditor = wixCodeApi.window.viewMode === 'Editor';
  const isPreview = wixCodeApi.window.viewMode === 'Preview';
  const isSite = wixCodeApi.window.viewMode === 'Site';
  const isSSR = wixCodeApi.window.rendering.env === 'backend';
  const isDebug = wixCodeApi.location.query.debug;
  const devToolsStore = initDevToolsStore(platformAPIs);
  const sentry = createSentryMonitor({
    flowAPI,
    isSSR,
    environment: ENVIRONMENT.APP,
    appParams,
  });

  const fedopsLogger = flowAPI.fedops;

  const initialLoadPromises = [];
  const language = getCurrentSiteLanguage(wixCodeApi, flowAPI, devToolsStore);

  let onSettingsChange = () => {};

  const log = createLogger(isDebug, isProduction);

  log('createForumAppController', {
    appParams,
    wixCodeApi,
    isSSR,
    language,
  });

  const pageReady = () => {
    log('createForumAppController.pageReady -> start');

    return Promise.resolve(
      wixCodeApi.site.getSectionUrl({ sectionId: FORUM_SECTION_ID, appDefinitionId: FORUM_APP_ID }),
    )
      .then(({ url: sectionUrl }) => {
        let router, wixCommentsApi;
        const getRouter = () => router;
        const store = createReduxStore({
          appParams,
          wixCodeApi,
          compId,
          reducers: rootReducer,
          isDebug,
          fedopsLogger,
          getRouter,
          platformAPIs,
          language,
          isSSR,
          sentry,
          isSite,
          config,
          flowAPI,
        });
        const actions = createControllerActions({
          wixCodeApi,
          store,
          fedopsLogger,
          BiLocationTracker,
          sentry,
        });
        const actionsPromisified = createControllerPromisifiedActions({
          wixCodeApi,
          compId,
          store,
          sentry,
        });
        let location;
        const locationPromise = transformWixCodeLocation(wixCodeApi, sectionUrl).then(
          l => (location = l),
        );

        const commentsPromise = store
          .dispatch(fetchTranslations(language))
          .then(() => store.dispatch(fetchExperiments(flowAPI.experiments.experiments)))
          .then(() => {
            if (isExperimentEnabled(store.getState(), EXPERIMENT_WIX_COMMENTS)) {
              const duplexerClient = getDuplexerClient();
              return initializeCommentsController(
                { appParams, compId, config, platformAPIs, setProps, type, wixCodeApi },
                {
                  appDefinitionId: FORUM_APP_ID,
                  shouldAutoBindStateToSetProps: false,
                  liveUpdates: { duplexerClient },
                  httpClient: flowAPI.httpClient,
                },
              );
            }
          });
        initialLoadPromises.push(commentsPromise);
        initialLoadPromises.push(locationPromise);
        store.dispatch(initializeLocation());

        initialLoadPromises.push(
          initializeStoreBaseData({
            wixCodeApi,
            store,
            language,
            platformAPIs,
            config,
          }),
        );

        if (wixCodeApi.site.onInstanceChanged) {
          wixCodeApi.site.onInstanceChanged(({ instance }) => {
            store.dispatch(initialiseInstanceValues(instance));
            store.dispatch(setInstance(instance));
          }, appParams.appDefinitionId);
        }

        return Promise.all(initialLoadPromises)
          .then(([commentsApi]) => {
            wixCommentsApi = commentsApi;

            const postPagePaginationParamsStore = {
              value: null,
              update(newValue) {
                this.value = newValue;
              },
            };

            if (wixCommentsApi) {
              store.dispatch(setCommentsTranslations(wixCommentsApi));
              configureCommentsApi({
                wixCommentsApi,
                wixCodeApi,
                store,
                postPagePaginationParamsStore,
              });
            }

            router = createRouter(
              store,
              config,
              wixCodeApi,
              compId,
              wixCommentsApi,
              postPagePaginationParamsStore.update.bind(postPagePaginationParamsStore),
            );

            onSettingsChange = applySettingsChange(store, () =>
              doRouting({ store, router, location }),
            );

            if (wixCodeApi.location.onChange) {
              wixCodeApi.location.onChange(async ({ path }) => {
                log('navigated to new path', path);
                store.dispatch(initializeLocation());
                const location = await transformWixCodeLocation(wixCodeApi, sectionUrl, path);
                doRouting({
                  store,
                  router,
                  location,
                  isInitialLoad: false,
                });
              });
            }

            return doRouting({
              store,
              router,
              location,
              isInitialLoad: true,
            });
          })
          .then(() => {
            log('createForumAppController.pageReady -> done');
            const state = store.getState();

            // after initial routing is done, we subscribe to get routing change as fast as route changes, without waiting for handler to resolve
            router.onMatch(match => store.dispatch(setRouterMatch(match)));

            setProps({
              ...state,
              actions,
              actionsPromisified,
              cssBaseUrl: appParams.baseUrls.staticsBaseUrl,
              sentryRelease: getStaticsVersionFromUrl(appParams.baseUrls.staticsBaseUrl),
              isRTL: isRtlLanguage(language),
              isSSR,
              devToolsStore,
              fitToContentHeight: true,
            });

            if (isEditor || isPreview) {
              listenToSettingsChange(store, () => doRouting({ store, router, location }));
            }

            if (isEditor || isPreview) {
              initializeNavigationHandlerForEditor({ store, router });
            }

            refreshDataOnLogin({ wixCodeApi, store, router });
            wixCommentsApi?.bindStateToSetProps();
            subscribeToChange(store, partialState => setProps({ ...partialState }));
            devToolsStore.onChange(latestDevToolsStore =>
              setProps({ devToolsStore: latestDevToolsStore }),
            );
          })
          .catch(
            handleError({
              store,
              actions,
              actionsPromisified,
              setProps,
              appParams,
              sentry,
              isSSR,
            }),
          );
      })
      .catch(
        handleError({
          setProps,
          appParams,
          sentry,
          isSSR,
        }),
      );
  };

  const controller = Promise.resolve({
    pageReady: () => {
      if (wixCodeApi.window.viewMode === 'Editor') {
        return handleProvisioning(
          appParams,
          fedopsLogger,
          wixCodeApi,
          setProps,
          pageReady,
          flowAPI,
        );
      }
      return pageReady();
    },
    updateConfig: ($w, config) => {
      console.log('[APP] updateConfig', config);
      onSettingsChange(config);
    },
    exports: () => ({
      onBeforePostCreate: onBeforePostCreateHook.setHook,
      onBeforePostUpdate: onBeforePostUpdateHook.setHook,
      onBeforeCommentCreate: onBeforeCommentCreateHook.setHook,
      onBeforeCommentUpdateHook: onBeforeCommentUpdateHook.setHook,
      onBeforeCommentUpdate: onBeforeCommentUpdateHook.setHook,
      onFetchExternalLinks: onFetchExternalLinksHook.setHook,
    }),
  });

  controller.catch(console.error);

  return controller;
};

function createReduxStore({
  reducers,
  appParams,
  wixCodeApi,
  compId,
  isDebug,
  fedopsLogger,
  platformAPIs,
  getRouter,
  language,
  isSSR,
  sentry,
  isSite,
  config,
  flowAPI,
}) {
  const p = {};
  const getInstance = () => getCurrentInstance(wixCodeApi);

  const isEditor = wixCodeApi.window.viewMode === 'Editor';
  const isPreview = wixCodeApi.window.viewMode === 'Preview';

  function createBiMiddleware(executor) {
    return initBiMiddleware({
      logger: flowAPI.bi,
      executor,
      instance: getInstance(),
      biLocation: BiLocationTracker,
      isSite,
      config,
      platformAPIs,
      wixCodeApi,
    });
  }

  function createRequestWithBaseUrl(baseUrl) {
    const disableRetryOnError = flowAPI.experiments.enabled(EXPERIMENT_DISABLE_API_RETRIES);

    return createRequest({
      baseUrl,
      getInstance,
      locale: language,
      trackError: error => p.store.dispatch(addErrorState(error)),
      logResponse: isDebug ? response => p.store.dispatch(addDebugState(response)) : undefined,
      petriOvr: wixCodeApi.location.query.petri_ovr,
      siteRevision: wixCodeApi.site.revision,
      httpClient: flowAPI.httpClient,
      isSSR,
      disableRetryOnError,
    });
  }

  const baseUrls = appParams.baseUrls;

  const baseUrl =
    wixCodeApi.window.rendering.env === 'backend'
      ? makeRelativeIfNeeded(baseUrls.apiBaseUrlClient)
      : baseUrls.apiBaseUrlClient;
  const badgesApiBaseUrl =
    wixCodeApi.window.rendering.env === 'backend'
      ? makeRelativeIfNeeded(baseUrls.apiBadgesBaseUrlClient)
      : baseUrls.apiBadgesBaseUrlClient;
  const membersGroupsApiBaseUrl =
    wixCodeApi.window.rendering.env === 'backend'
      ? makeRelativeIfNeeded(baseUrls.apiMembersGroupsBaseUrlClient)
      : baseUrls.apiMembersGroupsBaseUrlClient;
  const siteMembersApiBaseUrl =
    wixCodeApi.window.rendering.env === 'backend'
      ? makeRelativeIfNeeded(baseUrls.apiSiteMembersBaseUrlClient)
      : baseUrls.apiSiteMembersBaseUrlClient;
  const paidPlansApiBaseUrl =
    wixCodeApi.window.rendering.env === 'backend'
      ? makeRelativeIfNeeded(baseUrls.apiPaidPlansBaseUrlClient)
      : baseUrls.apiPaidPlansBaseUrlClient;
  const wixCommentsExperimentBaseUrl =
    wixCodeApi.window.rendering.env === 'backend'
      ? makeRelativeIfNeeded('/_serverless/comments-forum-integration')
      : `/_serverless/comments-forum-integration`;
  const oembedRequestBaseUrl =
    wixCodeApi.window.rendering.env === 'backend'
      ? makeRelativeIfNeeded(baseUrls.oembedBaseUrlClient)
      : baseUrls.oembedBaseUrlClient;
  const oembedRequestRCEBaseUrl =
    wixCodeApi.window.rendering.env === 'backend'
      ? makeRelativeIfNeeded('/rich-content/oembed')
      : `/rich-content/oembed`;
  const request = createRequestWithBaseUrl(baseUrl);
  const badgesRequest = createRequestWithBaseUrl(badgesApiBaseUrl);
  const oembedRequest = createRequestWithBaseUrl(oembedRequestBaseUrl);
  const oembedRequestRCE = createRequestWithBaseUrl(oembedRequestRCEBaseUrl);
  const membersGroupsRequest = createRequestWithBaseUrl(membersGroupsApiBaseUrl);
  const siteMembersRequest = createRequestWithBaseUrl(siteMembersApiBaseUrl);
  const paidPlansRequest = createRequestWithBaseUrl(paidPlansApiBaseUrl);
  const wixCommentsExperimentApi = createRequestWithBaseUrl(wixCommentsExperimentBaseUrl);

  p.store = createStore(
    enableBatching(reducers),
    composeWithReduxDevTools(wixCodeApi)(
      applyMiddleware(
        createThrottleMiddleware({
          sockets: {
            invokeAsBatch: true,
            debounce: 5000,
            options: {
              maxWait: 10000,
              trailing: true,
            },
          },
        }),
        thunk.withExtraArgument({
          request,
          badgesRequest,
          membersGroupsRequest,
          oembedRequest,
          oembedRequestRCE,
          siteMembersRequest,
          paidPlansRequest,
          wixCodeApi,
          platformAPIs,
          compId,
          appParams,
          fedopsLogger,
          getRouter,
          sentry,
          wixCommentsExperimentApi,
          httpClient: flowAPI.httpClient,
        }),
        globalLoaderMiddleware(),
        createRefreshAfterOnlineMigrationMiddleware(),
        createFastFormSubmitMiddleware({}),
        createRouterMiddleware(getRouter),
        createModalClosedMiddleware({ modalResolverMapByType, createShowMessage }),
        createModalOpenedMiddleware({ modalDataLoaderByType }),
        createMessageMiddleware({ resolvers: messageResolvers }),
        createInteractionsMiddleware(fedopsLogger, {
          [CREATE_POST_SUCCESS]: CREATE_POST,
          [UPDATE_POST_SUCCESS]: UPDATE_POST,
          [INCREMENT_POST_LIKE_COUNT_SUCCESS]: LIKE_POST,
          [DELETE_POST_SUCCESS]: DELETE_POST,
          [CREATE_COMMENT_SUCCESS]: CREATE_COMMENT,
          [UPDATE_COMMENT_SUCCESS]: UPDATE_COMMENT,
          [INCREMENT_COMMENT_LIKE_COUNT_SUCCESS]: LIKE_COMMENT,
          [DELETE_COMMENT_SUCCESS]: DELETE_COMMENT,
        }),
        createLocalStorageMiddleware(platformAPIs),
        createBiMiddleware(uouBiEventsExecutor),
        isSSR || !isSite
          ? noopMiddleware
          : createDuplexerMiddleware({
              duplexerConnection: createDuplexerClient({
                duplexerSocketsServerUrl: 'duplexer.wix.com',
                getInstance: () => getCurrentInstance(wixCodeApi),
                useHttpStreaming: false,
              }).connect({ appDefId: appParams.appDefinitionId }),
              eventHandlers: socketEventHandlers,
              onError: error => sentry.captureException(error),
            }),
        isEditor || isPreview ? forumDisplayStateMiddleware() : noopMiddleware,
      ),
    ),
  );

  return p.store;
}

function initializeStoreBaseData({ wixCodeApi, store, language, platformAPIs, config }) {
  const promises = [
    initUserDependentStoreBaseData({ store, wixCodeApi }),
    isMaInstalled(wixCodeApi).then(isInstalled => {
      if (isInstalled) {
        store.dispatch(initializeMaNavigation());
        store.dispatch(initIsMembersChatEnabled());
      }
    }),
    store.dispatch(setCommunitiesContext()),
    store.dispatch(setBasicParams({ language, pageNumber: platformAPIs.bi.pageNumber })),
    store.dispatch(setStyleParams(config.style.styleParams)),
    store.dispatch(fetchLocalStorage()),
    store.dispatch(emitForumOpened()),
    store.dispatch(setCustomRoutes()),
    store.dispatch(setInstalledApps()),
  ];
  return Promise.all(promises);
}

function initUser({ store, wixCodeApi }) {
  const user = wixCodeApi.user.currentUser;
  const { biToken } = parseInstance(getCurrentInstance(wixCodeApi));
  const isSiteSaved = biToken !== undefined;
  return user.loggedIn && user.id && isSiteSaved ? store.dispatch(fetchUser()) : Promise.resolve();
}

function initUserDependentStoreBaseData({ store, wixCodeApi, refetchExperiments }) {
  const { biToken } = parseInstance(getCurrentInstance(wixCodeApi));
  const isSiteSaved = biToken !== undefined;

  return Promise.all([
    initUser({ store, wixCodeApi }),
    store.dispatch(fetchForumData()).catch(handleInitialRequestFailure('fetchForumData')),
    store.dispatch(fetchAllCategories()).catch(handleInitialRequestFailure('fetchAllCategories')),
    isSiteSaved ? store.dispatch(fetchBadges()) : Promise.resolve(),
    isSiteSaved
      ? store
          .dispatch(fetchMembersGroupsAndPaidPlans())
          .then(
            () =>
              !isSSR(store.getState()) && store.dispatch(showActivateSubscriptionMessageIfNeeded()),
          )
          .catch(() => console.warn('Paid plans initial data fetch failed')) // don't crash the app in case paid plan fetch fails
      : Promise.resolve(),
    refetchExperiments ? store.dispatch(fetchExperiments()) : Promise.resolve(),
    store.dispatch(initialiseInstanceValues(getCurrentInstance(wixCodeApi))),
  ]);
}

function refreshDataOnLogin({ wixCodeApi, store, router }) {
  wixCodeApi.user.onLogin(
    once(() => {
      setTimeout(() => {
        initUserDependentStoreBaseData({ store, wixCodeApi, refetchExperiments: true })
          .then(() => doRouting({ store, router, location: getLocation(store.getState()) }))
          .then(() => invokeSavedAction(store));
      });
    }),
  );
}

function doRouting({
  router,
  location,
  store,
  isInitialLoad = true,
  shouldTransformOldLink = true,
}) {
  store.dispatch(setLocation(location));

  return router
    .match(
      shouldTransformOldLink ? transformOldLinks(location).pathname : location.pathname,
      [],
      location.query,
    )
    .then(match => {
      isInitialLoad && store.dispatch(setRouterMatch(match));
    });
}

function transformOldLinks(location) {
  let pathname = location.pathname;
  try {
    pathname = decodeURIComponent(location.pathname);
  } catch {
    // Already decoded, skipping;
  }
  const pageQueryMatch = pathname.match(/page=([^&]*)&dl=([^&]*)/);
  const searchQueryMatch = pathname.match(/query=([^&]*)/);
  const searchQuery = location.query.query || (searchQueryMatch && searchQueryMatch[1]);
  if (location.pathname.match(/^\/search\/(?:posts|members)/) && searchQuery) {
    return makeNewLocation(location, `/search/${searchQuery}`);
  } else if (location.query.page) {
    return makeNewLocation(location, `${location.pathname}/p-${location.query.page}`);
  } else if (pageQueryMatch) {
    const url = pathname.split('?');
    return makeNewLocation(
      location,
      `${url[0]}/p-${pageQueryMatch[1]}${
        pageQueryMatch[2] ? `/dl-${pageQueryMatch[2].split('/').join('-')}` : ''
      }`,
    );
  }
  return location;
}

function makeNewLocation(location, pathname) {
  return {
    ...location,
    pathname,
  };
}

function createLogger(isDebug, isProduction) {
  return (...args) => {
    if (!isProduction || isDebug) {
      console.log(...args);
    }
  };
}

function composeWithReduxDevTools() {
  return f => f;

  // if you need this, just reenable
  // const shouldEnable =
  //   wixCodeApi.location.query.debugStore && wixCodeApi.window.rendering.env !== 'backend';

  // return shouldEnable
  //   ? composeWithDevTools({
  //       name: 'fapp',
  //       realtime: shouldEnable,
  //       port: 8000,
  //       suppressConnectErrors: true,
  //     })
  //   : f => f;
}

function initializeNavigationHandlerForEditor({ store, router }) {
  if (typeof window !== 'undefined') {
    window.__navigateWithinForumInternally = pathname => {
      doRouting({
        store,
        router,
        location: { pathname },
        isInitialLoad: false,
        shouldTransformOldLink: false,
      });
    };
  }
}
