import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../reducers/app-dispatch';
import { RootState } from '../types/store-types';
import { CommonRequest } from '../types';

interface Subscribe {
  _id: string;
  type: 'post' | 'category';
}

export const SUBSCRIBE_REQUEST = 'subscribe/REQUEST';
export const SUBSCRIBE_SUCCESS = 'subscribe/SUCCESS';
export const SUBSCRIBE_FAILURE = 'subscribe/FAILURE';

export const subscribeRequest = createAction<Subscribe>(SUBSCRIBE_REQUEST);
export const subscribeSuccess = createAction<Subscribe>(SUBSCRIBE_SUCCESS);
export const subscribeFailure = createAction<Subscribe>(SUBSCRIBE_FAILURE);

export function subscribe(entity: Subscribe) {
  return (dispatch: AppDispatch, getState: RootState, { request }: { request: CommonRequest }) => {
    dispatch(subscribeRequest(entity));

    const promise = request.post(`/subscriptions/subscribe`, entity);

    return promise
      .then(() => dispatch(subscribeSuccess(entity)))
      .catch(() => dispatch(subscribeFailure(entity)))
      .then(() => promise);
  };
}
