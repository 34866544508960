import { createAction } from '@reduxjs/toolkit';
import { getCurrentUser } from '../../common/store/current-user/current-user-selectors';
import { AppDispatch } from '../reducers/app-dispatch';
import { RootState } from '../types/store-types';
import { CommonRequest } from '../types';

export const INCREMENT_COMMENT_LIKE_COUNT_REQUEST = 'comment/INCREMENT_LIKE_COUNT_REQUEST';
export const INCREMENT_COMMENT_LIKE_COUNT_SUCCESS = 'comment/INCREMENT_LIKE_COUNT_SUCCESS';
export const INCREMENT_COMMENT_LIKE_COUNT_FAILURE = 'comment/INCREMENT_LIKE_COUNT_FAILURE';

export const incrementCommentLikeCountRequest = createAction(
  INCREMENT_COMMENT_LIKE_COUNT_REQUEST,
  ({
    commentId,
    postId,
    currentUser,
  }: {
    commentId: string;
    postId: string;
    currentUser: any;
  }) => ({ payload: { _id: commentId, postId, currentUser } }),
);
export const incrementCommentLikeCountFailure = createAction(
  INCREMENT_COMMENT_LIKE_COUNT_FAILURE,
  ({
    commentId,
    postId,
    currentUser,
  }: {
    commentId: string;
    postId: string;
    currentUser: any;
  }) => ({ payload: { _id: commentId, postId, currentUser } }),
);

export const incrementCommentLikeCountSuccess = createAction(
  INCREMENT_COMMENT_LIKE_COUNT_SUCCESS,
  ({
    commentId,
    postId,
    currentUser,
    count,
    isLiked,
  }: {
    commentId: string;
    postId: string;
    currentUser: any;
    count: number;
    isLiked: boolean;
  }) => ({
    payload: { _id: commentId, postId, currentUser, likeCount: count, isLiked },
  }),
);

interface IncrementSuccessPostData {
  count: number;
  isLiked: boolean;
}

export function incrementCommentLikeCount({
  commentId,
  postId,
}: {
  commentId: string;
  postId: string;
}) {
  return (dispatch: AppDispatch, getState: RootState, { request }: { request: CommonRequest }) => {
    const currentUser = getCurrentUser(getState());
    dispatch(incrementCommentLikeCountRequest({ commentId, postId, currentUser }));
    const promise = request.post<IncrementSuccessPostData>(
      `/posts/${postId}/comments/${commentId}/likes`,
      {},
    );
    return promise
      .then(data => {
        dispatch(incrementCommentLikeCountSuccess({ commentId, postId, currentUser, ...data }));
      })
      .catch(() => dispatch(incrementCommentLikeCountFailure({ commentId, postId, currentUser })))
      .then(() => promise);
  };
}
