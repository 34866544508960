import { FETCH_POSTS_SUCCESS, fetchPostsSuccess } from '../../actions/fetch-posts';
import {
  FETCH_FOOTER_POSTS_SUCCESS,
  fetchFooterPostsSuccess,
} from '../../actions/fetch-footer-posts';
import { UserActivityState } from './user-activity-state';
import { PAGE_OPENED, pageOpened } from '../../actions/page-opened';
import { POST_PAGE } from '../../services/detect-route';
import {
  MARK_POSTS_AS_READ_SUCCESS,
  MARK_POST_AS_READ_SUCCESS,
  UserActivityTypes,
} from './user-activity.actions';

const initialState: UserActivityState = {
  newPosts: [],
};

type Post = {
  _id: string;
  isNew?: boolean;
};

type Actions =
  | UserActivityTypes
  | ReturnType<typeof fetchPostsSuccess>
  | ReturnType<typeof fetchFooterPostsSuccess>
  | ReturnType<typeof pageOpened>;

export function userActivity(state = initialState, action: Actions) {
  switch (action.type) {
    case FETCH_FOOTER_POSTS_SUCCESS:
    case FETCH_POSTS_SUCCESS:
      return {
        newPosts: Array.from(
          new Set([
            ...state.newPosts,
            ...action.payload.filter((p: Post) => p.isNew === true).map((p: Post) => p._id),
          ]),
        ),
      };
    case PAGE_OPENED:
      if (action.payload.page === POST_PAGE) {
        return {
          newPosts: state.newPosts.filter(id => id !== action.payload.post._id),
        };
      }
      return state;
    case MARK_POST_AS_READ_SUCCESS:
      return {
        newPosts: state.newPosts.filter(id => id !== action.payload.postId),
      };
    case MARK_POSTS_AS_READ_SUCCESS:
      if (action.payload.postIds) {
        return {
          newPosts: state.newPosts.filter(id => !action.payload.postIds.includes(id)),
        };
      }

      return {
        newPosts: [],
      };
    default:
      return state;
  }
}
